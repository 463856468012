<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value"
          max-width="900px"
          persistent>
    <v-card class="d-flex flex-column">
      <v-card-title>
        {{getTitle}}<br>
        {{getSubTitulo}}
        <v-spacer/>
        <v-btn icon @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="py-4 flex-grow-1" v-if="value">
        <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
          <v-row>
            <v-col  cols="12" md="12">
              <v-card class="px-3">
                <v-card-text class="subtitle-1 font-weight-light">
                  <v-row>
                    <v-col cols="12" md="4">
                      <lookup-dominio label="* Tipo do Anexo"
                      dense
                                      hint="Selecione o Tipo do Anexo"
                                      v-model="anexo.tipoAnexo"
                                      :type="lookups.arrTipoAnexo"
                                      :hidden-clear="true"
                                      :rules="[
                                          this.$validators.string.required
                                      ]"/>
                    </v-col>                    
                    <v-col cols="12" md="6">
                        <v-file-input
                            sense
                            accept=".pdf"
                            label="Escolha um arquivo PDF de até 20MB"
                            v-model="anexo.arquivo"
                            :rules="[
                                v => this.$validators.number.tamanhoMaxArquivo(v, 20)
                            ]"
                            ></v-file-input>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-btn color="primary" @click="save" :disabled="!formValid">Inserir</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="px-3" :loading="loading">
                <v-card-text class="subtitle-1 font-weight-light">
                  <v-row>
                    <v-col cols="12">
                      <v-chip color="teal" text-color="white" label class="ma-2" 
                      v-for="anexo in listaAnexos" :key="anexo.id">
                        <span class="v-chip__content">
                          <v-icon left>mdi-floppy</v-icon>
                          {{anexo.arquivo}}
                          <v-icon small right @click="visualizar(anexo.arquivo)">mdi-eye-arrow-right-outline</v-icon>
                          <v-icon small right @click="remover(anexo.id, anexo.arquivo)">mdi-trash-can-outline</v-icon>
                        </span>
                      </v-chip>
                    </v-col>                    
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>            
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="primary" @click="$emit('input', false)">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LookupDominio from "../../../components/shared/common/LookupDominio";
import {ItemDeDominio} from "../../../api/itemsDominio";
import {findAll as findAllAnexos, create, remove} from "../../../api/anexos";
import {exibirAnexo}  from "../../../api/relatorios";
import {ApiError} from "../../../api/core";

export default {
  name: "BaixaManualFaturasDialog",
  components: {LookupDominio},
  props: ['value','item'],
  data () {
    return {
      lookups: {
        arrTipoAnexo: ItemDeDominio.ARR_TIPO_ANEXO,
      },
      formValid: false,
      anexo: {
        id:"",
        idContrato:"", 
        idModificacao:"", 
        arquivo:null,
        tipoAnexo:"",
      },
      loading: false,
      error: null,
      listaAnexos: [],
    }
  },
  computed: {
    getTitle() {
      return 'Gerenciar Anexos';
    },
    getSubTitulo(){
      return (this.item?.dono === "C" ? "Contrato: " +this.item.descricaoCompleta : "Modificação: " + this.item?.numAditivo);
    }
  },
  watch: {  
      'item': {
      deep: true,
      handler(newValue) {
        this.refresh(newValue.id);
      }
    },
  },
  methods: {
    async save() {
      try {
        if(this.$refs.form.validate()) {
          if (this.item.dono === "C") {
            this.anexo.idContrato = this.item.id;
          } else {
            this.anexo.idModificacao = this.item.id;
          }
          this.loading = true;
          await create(this.anexo);
          this.$toast.success(`Anexo cadastrado com sucesso.`, {
            timeout: 3000
          })
          this.refresh(this.item.id);
        }
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível cadastrar o Anexo.`, {
            timeout: 6000
          })
        }
      }
    },
    async refresh(id) {
      try {
        this.listaAnexos = [];
        this.error = null;
        this.loading = true;
        let shearch = this.item.dono === "C" ? {idContrato: id} : {idModificacao: id};
        const page = await findAllAnexos(1, shearch);
        this.listaAnexos = page.elements;
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
    async remover(id, arquivo) {
      try {
        await remove(id, arquivo);
        this.$toast.success(`Anexo removido com sucesso.`, {
          timeout: 3000
        });
        this.refresh(this.item.id);
      } catch (err) {
        this.$toast.error(`Não foi possível remover o Anexo.`, {
          timeout: 6000
        })
      }
    },
    async visualizar(nome){
      await exibirAnexo({
        formatoSaida: "pdf",
        arquivo: nome
      });
    }
  },
}
</script>

<style scoped>
  .verde {
    color: green;
  }
  .vermelho {
    color: red;
  }
</style>