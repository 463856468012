"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.exibirAnexo = exports.downloadBoleto = exports.downloadRecibo = exports.downloadReport = exports.findAvailableOrders = exports.ReportType = void 0;
const core_1 = require("./core");
var ReportType;
(function (ReportType) {
    ReportType[ReportType["REL_LOG_COMUNICACAO"] = 0] = "REL_LOG_COMUNICACAO";
    ReportType[ReportType["REL_CONTRATO"] = 1] = "REL_CONTRATO";
})(ReportType = exports.ReportType || (exports.ReportType = {}));
const ORDER_ACTIONS = {
    [ReportType.REL_LOG_COMUNICACAO]: "ordRelLogComunicacao",
    [ReportType.REL_CONTRATO]: "ordRelContrato",
};
const REPORT_ACTIONS = {
    [ReportType.REL_LOG_COMUNICACAO]: 'relatorioLogComunicacao',
    [ReportType.REL_CONTRATO]: 'relatorioContratos',
};
function findAvailableOrders(reportType) {
    return core_1.get('ordenacao', ORDER_ACTIONS[reportType])
        .then(({ dados }) => dados.map(dado => ({
        name: dado.nome,
        field: dado.campo
    })));
}
exports.findAvailableOrders = findAvailableOrders;
function downloadReport(reportType, search) {
    return core_1.downloadFile('Relatorios', REPORT_ACTIONS[reportType], Object.assign({}, search));
}
exports.downloadReport = downloadReport;
function downloadRecibo(search) {
    return core_1.downloadFile('Relatorios', 'reciboFartura', Object.assign({}, search));
}
exports.downloadRecibo = downloadRecibo;
function downloadBoleto(search) {
    return core_1.downloadFile('Relatorios', 'exibirBoleto', Object.assign({}, search));
}
exports.downloadBoleto = downloadBoleto;
function exibirAnexo(search) {
    return core_1.downloadFile('Relatorios', 'exibirAnexo', Object.assign({}, search));
}
exports.exibirAnexo = exibirAnexo;
