<template>
<div>
  <v-tabs v-model="tab" 
      background-color="#43C6D2"
      dark
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
  >
    <v-tabs-slider color="#EB8519"></v-tabs-slider>
    <v-tab>
      Informações da Modificação
    </v-tab>
    <v-tab>
      Datas e Valores
    </v-tab>
    <v-tab>
      Garantia
    </v-tab>    
  </v-tabs>
  <v-tabs-items v-model="tab">
    <v-tab-item> <!-- dados -->
      <v-card flat>
        <v-card-text> 
          <v-row>
            <v-col cols="12" md="4">
              <search-box 
                  :emit-all-properties="true"
                  label="* Contrato"
                  popup-label="Selecione um Contrato"
                  :id.sync="value.idContrato"
                  :descricaoCompleta.sync="value.descricaoContrato"
                  :hidden-clear="true"
                  :value="contrato"
                  :loader-fn="loaderContrato"
                  item-key="id"
                  item-text="descricaoCompleta"
                  :rules="[
                      this.$validators.notNullOrUndefined
                  ]">
                <template v-slot:empty>
                  <p class="ma-2">
                    Nenhum Tipo de Modificação encontrada
                  </p>
                </template>
              </search-box>
            </v-col>            
            <v-col cols="12" sm="2">
              <v-text-field label="* Número"
                        dense
                        :rules="[
                          this.$validators.string.required,
                          v => this.$validators.string.greaterThanOrEquals(v, 1),
                          v => this.$validators.string.lessThanOrEquals(v, 20),
                        ]"
                        v-model.trim="value.numAditivo"/>
            </v-col>        
            <v-col cols="12" md="3">
              <search-box
                  label="* Grupo"
                  popup-label="Selecione um Grupo"
                  :id.sync="value.idGrupo"
                  :descricao.sync="value.descricaoGrupo"
                  :hidden-clear="true"
                  :value="grupo"
                  :loader-fn="loaderGrupo"
                  item-key="id"
                  item-text="descricao"
                  :rules="[
                      this.$validators.notNullOrUndefined
                  ]">
                <template v-slot:empty>
                  <p class="ma-2">
                    Nenhum Grupo encontrado
                  </p>
                </template>
              </search-box>
            </v-col>
            <v-col cols="12" md="3">
              <search-box ref="comboTipoModificacao"
                  label="* Tipo de Modificação"
                  popup-label="Selecione um Tipo de Modificação"
                  :id.sync="value.idTipoModificacao"
                  :descricao.sync="value.descricaoTipoModificacao"
                  :hidden-clear="true"
                  :value="tipoModificacao"
                  :loader-fn="loaderTipoModificacao"
                  item-key="id"
                  item-text="descricao"
                  :rules="[
                      this.$validators.notNullOrUndefined
                  ]">
                <template v-slot:empty>
                  <p class="ma-2">
                    Nenhum Tipo de Modificação encontrada
                  </p>
                </template>
              </search-box>
            </v-col>      
            <v-col cols="12" sm="6">     
              <v-textarea 
                  dense
                  rows="6"
                  label="* Objeto"
                  :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 4000),
                  ]"
                  v-model.trim="value.objeto"/>
            </v-col>
            <v-col cols="12" sm="6">     
              <v-textarea 
                  dense
                  rows="6"
                  label="Observação"
                  :rules="[
                      v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 400),
                  ]"
                  v-model.trim="value.observacao"/>
            </v-col> 
          </v-row>
        </v-card-text>
      </v-card>        
    </v-tab-item>
    <v-tab-item> <!-- Datas e Valores -->
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3">
                <v-text-field label="* Data Início"
                              dense
                              type="date"
                              v-model="value.dtcInicioVigencia"
                              />
            </v-col>
            <v-col cols="12" sm="3">
                <v-text-field label="* Data Término"
                              dense
                              type="date"
                              v-model="value.dtcFinalVigencia"
                              />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field dense
                  label="Valor Mensal"
                  prefix="R$"
                  :rules="[
                      v => this.$validators.number.greaterThanOrEquals(v, 0)
                  ]"
                  v-model="value.valorMensal" type="number"/>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field dense
                  label="* Valor Global"
                  prefix="R$"
                  :rules="[
                      v => this.$validators.number.greaterThanOrEquals(v, 0)
                  ]"
                  v-model="value.valorGlobal" type="number"/>
            </v-col>
            <v-col cols="12" sm="12">
              <DetalharItemForm v-model="value" :titulo="'Detalhamento dos itens'"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item> <!-- Garantia -->
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-switch
                v-model="temGarantia"
                label="O contrato possui Garantia"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row v-show="temGarantia">
            <v-col cols="12" sm="6">
              <lookup-dominio label="Tipo da Garantia"
                            :hidden-clear="true"
                            hint="Selecione o Status"
                            v-model="value.idTipoGarantia"
                            :type="lookups.tipoGarantia"
                            />
            </v-col>  
            <v-col cols="12" sm="6">
              <v-text-field dense
                  label="Valor da Garantia"
                  prefix="R$"
                  v-model="value.valorGarantia" type="number"/>
            </v-col>            
            <v-col cols="12" sm="6">
                <v-text-field label="* Data Início Garantia"
                              dense
                              type="date"
                              v-model="value.dataInicioGarantia"/>
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field label="* Data Término Garantia"
                              dense
                              type="date"
                              v-model="value.dataTerminoGarantia"
                              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item> 
  </v-tabs-items>
</div>      
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
//PKs
import {findAll as findAllContrato} from "../../../api/contrato";
import {findAll as findAllGrupo} from "../../../api/grupoTipoModificacao";
import {findAll as findAllTipoModificacao, findOne as findOneTipoModificacao} from "../../../api/tipoModificacao";
import DetalharItemForm from "../forms/DetalharItemForm";

export default {
  name: "ModificacaoForm",
  components: {SearchBox, LookupDominio, DetalharItemForm},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      tab: null,
      lookups: {
        tipoGarantia: ItemDeDominio.ARR_TIPO_GARANTIA, 
      },
      temGarantia: false,
      loading: false,
      error: null,
    }
  },
  computed: {
    contrato(){
      if (this.value.idContrato == null) return null;
      return {
        id: this.value.idContrato,
        descricaoCompleta: this.value.descricaoContrato
      }      
    },
    grupo() {
      if (this.value.idGrupo == null) return null;
      return {
        id: this.value.idGrupo,
        descricao: this.value.descricaoGrupo
      }
    },
    tipoModificacao() {
      if (this.value.idTipoModificacao == null) return null;
      return {
        id: this.value.idTipoModificacao,
        descricao: this.value.descricaoTipoModificacao
      }
    },
  },
  watch: {
    'value.idGrupo': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$refs.comboTipoModificacao.resetValues();
          this.$refs.comboTipoModificacao.search();
        }
      }
    },
    'value.idTipoModificacao': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.carregaFlgValor(newValue);
        }
      }
    },
    'temGarantia': {
      deep: true,
      handler(newValue) {
        console.log('watch: ',newValue);
        if (newValue) {
          this.value.temGarantia = 'S';
        } else{
          this.value.temGarantia = 'N';
        }
      }      
    }    
  },  
  methods: {
    loaderContrato(page, search) {
      return findAllContrato(page, {
        nome: search
      })
    },    
    loaderGrupo(page, search) {
      return findAllGrupo(page, {
        nome: search
      })
    },
    loaderTipoModificacao(page, search) {
      return findAllTipoModificacao(page, {
        nome: search,
        idGrupo: this.value.idGrupo
      })
    },
    async carregaFlgValor(id) {
      this.value.flgValorTipoModificacao = await this.getTipoModificacao(id);
    },
    async getTipoModificacao(id) {
      if (id){
        try {
          const page = await findOneTipoModificacao(id);
          console.log(page.dados[0])
          let flgValor = page.dados[0].flgValor;
          return flgValor;
        } catch (err) {
          this.error = err;
        } finally {
        }
      }
    },
    marcaTemGarantia(valor) {
      console.log('marcaTemGarantia: ',valor);
      if (valor === "S"){
        this.temGarantia = true;
      } else {
        this.temGarantia = false;
      }
    }    
  },
  mounted() {
    this.carregaFlgValor(this.value.idTipoModificacao);
    this.marcaTemGarantia(this.value.temGarantia);
  }
}
</script>

<style scoped>

</style>