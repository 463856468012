<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <CreateEditModificacaoDialog :item="item" v-model="editDialogOpened"
                                @onSave="() => $refs.dataTable.refresh()"/>
    <GerenciarAnexosDialog :item="item" v-model="gerenciarAnexosDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>
    <div>
      <ModificacaoDataTable ref="dataTable"
                           @click:edit="editar"
                           @click:remove="remover"
                           @click:gerenciaAnexos="gerenciarAnexos"
                           :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import ModificacaoDataTable from "../../components/shared/datatables/ModificacaoDataTable";
import BaseView from "../BaseView";
import {findOne, remove} from "../../api/modificacao";
import {findAll as findAllContrato} from '../../api/contrato';
import {findAll as findAllGrupo} from '../../api/grupoTipoModificacao';
import {findAll as findAllTipoModificacao} from '../../api/tipoModificacao';
import CreateEditModificacaoDialog from "../../components/shared/dialogs/CreateEditModificacaoDialog";
import GerenciarAnexosDialog from "../../components/shared/dialogs/GerenciarAnexosDialog";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions} from "vuex";

export default {
  name: "index",
  components: {CreateEditModificacaoDialog, BaseView, ModificacaoDataTable, GerenciarAnexosDialog},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      gerenciarAnexosDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Número da Modificação',
          column: 'numAditivo',
          type: 'number'
        },
        {
          name: 'Contrato',
          column: 'idContrato',
          type: 'autocomplete',
          optionsFactory: (search) => findAllContrato(1,{
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricaoCompleta
                }))
              })
        },
        {
          name: 'Grupo Modificação',
          column: 'idGrupo',
          type: 'autocomplete',
          optionsFactory: (search) => findAllGrupo(1,{
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        },         
        {
          name: 'Tipo do Modificação',
          column: 'idTipoModificacao',
          type: 'autocomplete',
          optionsFactory: (search) => findAllTipoModificacao(1,{
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        },      
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {
        idContrato: null,
        descricaoContrato: "",
        idGrupo: null,
        descricaoGrupo: "",
        flgValorTipoModificacao: "",
        idTipoModificacao: null,
        descricaoTipoModificacao: "",
        ordemModif: "",
        numAditivo: "",
        dtcInicioVigencia: "",
        dtcFinalVigencia: "",
        valorAditivo: 0,
        desObservacao: 0,
        itens: [],
        temGarantia: 'N',      
      };
      this.editDialogOpened = true;
    },
    async editar(modificacao) {
      const data = await this.getModificacao(modificacao.id);
      this.item = data;
      this.editDialogOpened = true;
    },
    gerenciarAnexos(item){
      this.item = JSON.parse(JSON.stringify(item));
      this.gerenciarAnexosDialogOpened = true;
    },
    async getModificacao(id) {
      if (id){
        try {
          this.error = null;
          this.loading = true;
          const page = await findOne(id);
          let modificacao = page.dados[0];
          return modificacao;
        } catch (err) {
          this.error = err;
        } finally {
          this.loading = false;
        }
      }
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Modificação removida com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        this.$toast.error(`Não foi possível remover a Modificação.`, {
          timeout: 6000
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>